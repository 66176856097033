import { FC } from 'react';
import { StyledContent, StyledLine } from './Divider.styled';
import { DividerProps } from './Divider.d';
import { Box } from '../Box';
import { Typography } from '../Typography/Typography';
import { COLORS } from '../../../theme/colors';

export const Divider: FC<DividerProps> = ({ content, ...props }) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledLine {...props} mr={!!content && 1} />
      {content && (
        <>
          <StyledContent variant="caption" color={COLORS.typography.caption}>
            {content}
          </StyledContent>
          <StyledLine {...props} ml={1} />
        </>
      )}
    </Box>
  );
};
