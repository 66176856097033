import {
  ExpandableCell,
  ExpandableCellContent,
  StyledRecommendationsContainer,
  StyledRow,
} from './ExpandableSmartFundBenchmarkingRow.styled';
import { ExpandableSmartFundBenchmarkingRowProps } from './ExpandableSmartFundBenchmarkingRow.d';
import { CollapseTableTabs } from 'components/common/Tabs/CollapseTableTabs/CollapseTableTabs';
import { useEffect, useState } from 'react';
import { fetchSmartBenchmarkingFund } from 'services/SmartBenchmarking';
import { SmartBenchmarkingFund } from 'types/SmartBenchmarking.d';
import { getTabsData } from './config';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Recommendations } from 'components/Modules/AssetClasses/Recommendations';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const ExpandableSmartFundBenchmarkingRow = ({
  columns,
  rowData,
  isOpen,
  updatePositions,
}: ExpandableSmartFundBenchmarkingRowProps) => {
  const { id } = rowData;
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [, setSearchParams] = useSearchParams();
  const [fundData, setFundData] = useState<SmartBenchmarkingFund>();

  const getFundData = async (id: string) => {
    const response = await fetchSmartBenchmarkingFund(id);
    if (response.ok) {
      setFundData(response.parsedBody);
    }
    return response;
  };

  useEffect(() => {
    if (isOpen) {
      getFundData(id).then((response) => {
        if (response.ok) {
          updatePositions && updatePositions();
          const newSearchParams = new URLSearchParams();
          newSearchParams.append('collapse-table-tab', 'stammdaten');
          newSearchParams.append('id', id);

          const originalSearchParams = new URLSearchParams(search);
          originalSearchParams.forEach((value, key) => {
            newSearchParams.append(key, value);
          });

          customNavigate(pathname + '?' + newSearchParams.toString());
        }
      });
    } else {
      updatePositions && updatePositions();
      const newSearchParams = new URLSearchParams(search);
      newSearchParams.delete('table-tab');
      newSearchParams.delete('collapse-table-tab');
      newSearchParams.delete('id');
      setSearchParams(newSearchParams);
    }
  }, [isOpen, id]);

  return columns && columns.length && isOpen && fundData ? (
    <StyledRow key={id}>
      <ExpandableCell colSpan={columns.length + 2}>
        <ExpandableCellContent {...{ isOpen }}>
          {!!fundData.recommendations_for_action &&
            fundData.recommendations_for_action !== null && (
              <StyledRecommendationsContainer mb={2}>
                <Recommendations
                  recommendations={fundData.recommendations_for_action}
                />
              </StyledRecommendationsContainer>
            )}
          {getTabsData(fundData).length !== 0 && (
            <CollapseTableTabs
              tabs={getTabsData(fundData).map((item) => ({
                label: item.label,
                content: item.content,
              }))}
            />
          )}
        </ExpandableCellContent>
      </ExpandableCell>
    </StyledRow>
  ) : (
    <></>
  );
};
