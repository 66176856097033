import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledHeadline = styled(Typography)`
  font-size: 24px;
  line-height: 34px;
`;

export const StyledForm = styled.form`
  padding-top: ${SIZES.spacing(4)};
  width: 40%;
`;

export const StyledButtonContainer = styled(Box)`
  padding-top: ${SIZES.spacing(5)};
  width: 51%;
`;
