import { forwardRef, useEffect } from 'react';
import {
  SaveFiltersPresetModalProps,
  SavePresetFields,
} from './SaveFiltersPresetModal.d';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer } from './SaveFiltersPresetModal.styled';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Input } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import { isValidForm } from 'utils/isValidForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { savePresetSchema } from './validators';

export const SaveFiltersPresetModal = forwardRef<
  HTMLDivElement,
  SaveFiltersPresetModalProps
>(({ isShown, toggle, onSubmit }, ref) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SavePresetFields>({
    resolver: yupResolver(savePresetSchema),
    mode: 'onBlur',
  });

  const onSaveFiltersPreset = ({ name }: SavePresetFields) => {
    onSubmit(name);
  };

  useEffect(() => {
    if (!isShown) {
      reset();
    }
  }, [isShown]);

  return (
    <Modal
      modalRef={ref}
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      title="Benennen Sie Ihre Filtervoreinstellung"
      px={4}
      pb={5}
      pt={4}
    >
      <StyledContainer pt={4}>
        <form onSubmit={handleSubmit(onSaveFiltersPreset)}>
          <Input
            placeholder="Benutzerdefinierten Namen eingeben"
            {...register('name')}
            error={!isValidForm(errors) && errors.name?.message}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
            <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
              Abbrechen
            </Button>
            <Button type="submit">Speichern</Button>
          </Box>
        </form>
      </StyledContainer>
    </Modal>
  );
});
