import { Box } from 'components/common/Box';
import { FC } from 'react';
import {
  StyledButtonContainer,
  StyledForm,
  StyledHeadline,
} from './ChangePasswordForm.styled';
import { Input } from 'components/common/Input';
import { isValidForm } from 'utils/isValidForm';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Button } from 'components/common/Buttons/Button';
import { useForm } from 'react-hook-form';
import { ChangePasswordFormProps } from './ChangePasswordForm.d';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema } from './validators';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { changePassword } from 'services/Auth';
import { raiseToast } from 'components/common/Toast/raiseToast';

export const ChangePasswordForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordFormProps>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onBlur',
  });
  const onSubmit = async (data: ChangePasswordFormProps) => {
    const formData = {
      old_password: data.old_password,
      new_password: data.password,
    };
    const response = await changePassword(formData);
    if (response.ok) {
      reset();
      raiseToast.success('Ihr Passwort wurde erfolgreich geändert.');
    }
  };

  return (
    <>
      <Box pb={4}>
        <StyledHeadline variant="h2">Ändern Sie Ihr Passwort</StyledHeadline>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Input
              label="Aktuelles Passwort"
              placeholder="Geben Sie Ihr aktuelles Passwort ein"
              type="password"
              {...register('old_password')}
              error={!isValidForm(errors) && errors.old_password?.message}
            />
            <Input
              label="Neues Passwort"
              placeholder="Geben Sie ein neues Passwort ein"
              type="password"
              {...register('password')}
              error={!isValidForm(errors) && errors.password?.message}
            />
            <Input
              label="Passwort bestätigen"
              placeholder="Geben Sie ein neues Passwort ein"
              type="password"
              {...register('confirm_password')}
              error={!isValidForm(errors) && errors.confirm_password?.message}
            />
          </Box>
          <Box pt={2} display="flex" gap={1} alignItems="center">
            <InfoIcon stroke={COLORS.info.selectedBorder} />
            <Typography variant="body" color={COLORS.typography.description}>
              Ihr Passwort muss mindestens 8 Zeichen lang sein.
            </Typography>
          </Box>
          <StyledButtonContainer>
            <Button fullWidth type="submit">
              Änderungen speichern
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </Box>
    </>
  );
};
