import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledHeadline = styled(Typography)`
  font-size: 24px;
  line-height: 34px;
`;

export const StyledForm = styled.form`
  padding-top: ${SIZES.spacing(4)};
  width: 82%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`;

export const StyledButtonContainer = styled(Box)`
  width: 51%;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.info.selectedBorder};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  transition-duration: 0.2s;
  text-decoration: none;

  &:hover {
    color: ${COLORS.typography.description};
  }
`;
