import { FC } from 'react';
import { InvitedByProps } from './InvitedBy.d';
import { ReactComponent as LikeIcon } from 'assets/Icons/like-shapes.svg';
import {
  StyledContainer,
  StyledEmailContainer,
  StyledTypography,
} from './InvitedBy.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';

export const InvitedBy: FC<InvitedByProps> = ({ name, lastName, company }) => {
  const invitedBy =
    name && lastName
      ? `${name} ${lastName} ${company ? `(${company})` : ''}`
      : name || '';

  return (
    <StyledContainer>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        style={{ minWidth: '230px' }}
      >
        <LikeIcon />
        <StyledTypography variant="body">
          Sie wurden eingeladen von:
        </StyledTypography>
      </Box>
      <StyledEmailContainer>
        <StyledTypography
          variant="body"
          color={COLORS.background.secondary}
          textAlign="center"
        >
          {invitedBy}
        </StyledTypography>
      </StyledEmailContainer>
    </StyledContainer>
  );
};
