import {
  activeManagementOptions,
  managementAffiliationOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  trackingErrorOptions,
  volumeOptions,
} from '../Tables/FCSmartFundBenchmarkingTable/config';

export const rows = [
  {
    categoryName: 'Stammdaten',
    isDifferencesHighlightable: false,
    data: [
      {
        id: 'issuer',
        label: 'Fondsgesellschaft',
      },
      {
        id: 'isin',
        label: 'ISIN',
      },
      {
        id: 'peer_group_name',
        label: 'Peergroup',
      },
      {
        id: 'edition_date',
        label: 'Auflagedatum',
      },
      {
        id: 'ongoing_charge',
        label: 'Laufende jährliche Kosten',
      },
      {
        id: 'performance_fee',
        label: 'Performance Gebühr',
      },
      {
        id: 'minimum_investment',
        label: 'Mindestanlage',
      },
    ],
  },
  {
    categoryName: 'Smart Benchmarking',
    isDifferencesHighlightable: true,
    data: [
      {
        id: 'volume',
        label: 'Fondsvolumen',
        options: volumeOptions,
      },
      {
        id: 'track_record',
        label: 'Track Record',
        options: trackRecordOptions,
      },
      {
        id: 'return',
        label: 'Rendite',
        options: returnOptions,
        data: [
          {
            id: 'return_short_term',
            label: 'Kurzfristig',
            options: returnOptions,
          },
          {
            id: 'return_medium_term',
            label: 'Mittelfristig',
            options: returnOptions,
          },
          {
            id: 'return_long_term',
            label: 'Langfristig',
            options: returnOptions,
          },
        ],
      },
      {
        id: 'risk',
        label: 'Risiko',
        options: riskOptions,
        data: [
          {
            id: 'volatility_short_term',
            label: 'Kurzfristig',
            options: riskOptions,
          },
          {
            id: 'volatility_medium_term',
            label: 'Mittelfristig',
            options: riskOptions,
          },
          {
            id: 'volatility_long_term',
            label: 'Langfristig',
            options: riskOptions,
          },
        ],
      },

      {
        id: 'total_active_management',
        label: 'Aktives Management',
        options: activeManagementOptions,
      },
      {
        id: 'tracking_error',
        label: 'Tracking error',
        options: trackingErrorOptions,
      },
      {
        id: 'management_affiliation',
        label: 'Managementzugeörigkeit',
        options: managementAffiliationOptions,
      },
    ],
  },
  {
    categoryName: 'ESG',
    isDifferencesHighlightable: true,
    data: [
      {
        id: 'credibility_score',
        label: 'ESG Credibility',
        options: 'number',
      },
      {
        id: 'action_score_fund',
        label: 'ESG Action Score Fonds',
        options: 'number',
      },
      {
        id: 'action_score_firm',
        label: 'ESG Action Score Fondsgesellschaft',
        options: 'number',
      },
    ],
  },
];
