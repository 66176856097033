import { FC, useState } from 'react';
import { InnerExpandableRowProps } from './InnerExpandableRow.d';
import { Box, TableRow } from '@mui/material';
import {
  StyledExpandableRowTableCell,
  StyledHeadTableCell,
  StyledHeadTableCellContainer,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellContainer,
  StyledTable,
} from '../ExpandableRow/ExpandableRow.styled';
import { CreatedColumnComparisonList } from '../VerticalCollapseTable.d';
import { addDotsToString } from 'utils/common';
import { ReactComponent as ArrowCollapseIcon } from 'assets/Icons/collapse-arrow.svg';
import { COLORS } from 'theme/colors';
import { ExpandableRow } from '../ExpandableRow';
import { getRowColor } from '../ExpandableRow/utils';

const MAX_LENGTH = 95;

export const InnerExpandableRow: FC<InnerExpandableRowProps> = ({
  isDifferencesHighlighted,
  isDifferencesHighlightable,
  insideRow,
  columns,
}) => {
  const pinnedColumn = columns[0];
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (header: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [header]: !prevOpen[header] }));
  };

  const isHighlighted = (row: string) => {
    return columns.some(
      (item) =>
        pinnedColumn &&
        pinnedColumn[row as keyof CreatedColumnComparisonList]?.toString() !==
          item[row as keyof CreatedColumnComparisonList]?.toString(),
    );
  };

  return (
    <>
      {insideRow.data && insideRow.data.length !== 0 && (
        <TableRow>
          <StyledExpandableRowTableCell colSpan={columns.length + 2}>
            <Box>
              <StyledTable>
                <StyledTableBody>
                  <TableRow key={insideRow.label}>
                    <StyledHeadTableCell
                      component="th"
                      scope="row"
                      align="left"
                      width="244px"
                      onClick={() => handleToggle(insideRow.label)}
                    >
                      <StyledHeadTableCellContainer
                        pr={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                      >
                        {insideRow.label}
                        <ArrowCollapseIcon
                          stroke={
                            open[insideRow.label]
                              ? COLORS.accent.primary
                              : COLORS.typography.main
                          }
                          style={{
                            transform: open[insideRow.label]
                              ? 'rotate(270deg)'
                              : 'rotate(90deg)',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </StyledHeadTableCellContainer>
                    </StyledHeadTableCell>
                    {columns.map((item, idx) => (
                      <StyledTableCell
                        key={`${
                          item[
                            insideRow.id as keyof CreatedColumnComparisonList
                          ]
                        }-${idx}`}
                        align="left"
                        width="325px"
                      >
                        <StyledTableCellContainer
                          color={
                            isDifferencesHighlighted
                              ? getRowColor(
                                  item[
                                    insideRow.id as keyof CreatedColumnComparisonList
                                  ] as string,
                                  insideRow.id,
                                  pinnedColumn,
                                  insideRow.options,
                                )
                              : COLORS.background.secondary
                          }
                        >
                          {((
                            item[
                              insideRow.id as keyof CreatedColumnComparisonList
                            ] as string
                          )?.length ?? 0) >= MAX_LENGTH
                            ? addDotsToString(
                                item[
                                  insideRow.id as keyof CreatedColumnComparisonList
                                ] as string,
                                MAX_LENGTH,
                              )
                            : (item[
                                insideRow.id as keyof CreatedColumnComparisonList
                              ] as React.ReactNode)}
                        </StyledTableCellContainer>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                  <ExpandableRow
                    isDifferencesHighlightable={isDifferencesHighlightable}
                    isDifferencesHighlighted={isDifferencesHighlighted}
                    open={open}
                    data={insideRow.data}
                    columns={columns}
                    row={insideRow.label}
                  />
                </StyledTableBody>
              </StyledTable>
            </Box>
          </StyledExpandableRowTableCell>
        </TableRow>
      )}
    </>
  );
};
