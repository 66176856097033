import { FC } from 'react';
import { OverviewDetailProps } from 'views/MainPage/MainPage.d';
import {
  StyledContainer,
  LabelContainer,
  StyledArrowIcon,
  StyledChip,
  StyledTypography,
} from './OverviewItem.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const OverviewItem: FC<OverviewDetailProps> = ({
  label,
  link,
  chips,
  hoverImageLink,
}) => {
  const { customNavigate } = useNavigation();

  return (
    <StyledContainer
      to={link}
      hoverImageLink={hoverImageLink}
      onClick={() => customNavigate(link)}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexWrap="wrap" gap={1}>
          {chips.map((chip) => (
            <StyledChip
              label={chip}
              customColor={COLORS.typography.main}
              customBackground={COLORS.background.grey}
              customBorderBolor={COLORS.stroke.main}
            />
          ))}
        </Box>
        <StyledArrowIcon to={link} />
      </Box>
      <LabelContainer>
        <StyledTypography variant="h4">{label}</StyledTypography>
      </LabelContainer>
    </StyledContainer>
  );
};
