import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import {
  FormControl,
  styled as MUIstyled,
  MenuItem,
  OutlinedInput,
  Select,
  outlinedInputClasses,
  selectClasses,
} from '@mui/material';
import {
  StyledInputContainerProps,
  StyledMenuItemContainerProps,
} from './MyFiltersButton.d';

export const StyledSelect = MUIstyled(Select)(() => ({
  [`& .${selectClasses.select}`]: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: COLORS.typography.description,
    fontSize: '14px',
    padding: '12px 24px',
    paddingRight: `42px !important`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${selectClasses.icon}`]: {
    right: 18,
    top: 18,
  },
}));

export const StyledInput = MUIstyled(OutlinedInput)(({ value }) => ({
  height: 48,
  [`& .${outlinedInputClasses.root}`]: {
    height: 48,
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.typography.description,
      fontWeight: 600,
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: '1px !important',
    borderColor: `${COLORS.stroke.main} !important`,
    borderRadius: '30px',
  },
  [`& .${outlinedInputClasses.focused}`]: {
    borderColor: `${COLORS.info.selectedBorder}`,
  },
}));
export const StyledFormControl = MUIstyled(FormControl)(() => ({
  position: 'relative',
}));

export const StyledMenuItemContainer = styled(
  MenuItem,
)<StyledMenuItemContainerProps>(({ isSelected }) => ({
  color: `${
    isSelected ? COLORS.info.selectedBorder : COLORS.typography.description
  } !important`,
  backgroundColor: `${
    isSelected ? COLORS.background.neutral : COLORS.background.secondary
  } !important`,
  '&:hover': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: COLORS.accent.primary,
  },
}));

export const StyledPlaceholder = styled.p`
  position: absolute;
  left: 21px;
  top: 1px;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.typography.description};
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 8.5px 0px;
  white-space: initial;
`;

export const StyledSpan = styled.span<StyledInputContainerProps>`
  color: ${({ isOpen }) =>
    isOpen ? COLORS.background.secondary : COLORS.typography.description};
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  padding-bottom: 0px;
`;

export const StyledInputContainer = styled.div<StyledInputContainerProps>`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  width: 100%;
  min-width: 143px;
  background-color: ${({ isOpen }) =>
    isOpen ? COLORS.info.selectedBorder : COLORS.background.secondary};
  border-radius: 30px;
  transition: background-color 0.2s;

  p {
    ${({ isOpen }) => isOpen && `color: ${COLORS.background.secondary};`}
  }

  svg {
    ${({ isOpen }) => isOpen && `stroke: ${COLORS.background.secondary};`}
  }

  fieldset {
    ${({ isOpen }) =>
      isOpen && `border-color: ${COLORS.info.selectedBorder} !important;`}
  }

  &:hover {
    background-color: ${COLORS.info.selectedBorder};

    p {
      color: ${COLORS.background.secondary};
    }
    svg {
      stroke: ${COLORS.background.secondary};
    }

    fieldset {
      border-color: ${COLORS.info.selectedBorder} !important;
    }

    span {
      color: ${COLORS.background.secondary};
    }
  }
`;

export const HiddenFirstMenuItem = MUIstyled(MenuItem)({
  display: 'none',
});
