import styled from 'styled-components';
import { Box } from '../../../components/common/Box';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  margin: 32px 20px;

  ${SIZES.media.phone} {
    width: 73%;
    margin: 25px auto;
  }

  ${SIZES.media.tablet} {
    width: 43%;
  }

  ${SIZES.media.desktop} {
    width: 33%;
  }
`;

export const ButtonsContainer = styled(Box)`
  height: 50px;
  margin-bottom: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    margin-bottom: ${SIZES.spacing(3)};
  }
`;
