import { INVITATION, INVITE_USERS } from 'urls/api';
import fetchAPI from './fetchApi';
import { InvitationData } from 'types/Invitation.d';

const jsonContentTypeHeader = { 'Content-Type': 'application/json' };

type UserInvitation = {
  email: string;
  name: string;
  last_name: string;
  gender: string;
  as_admin: boolean;
};

export const sendInvitation = async (data: UserInvitation[]) => {
  const response = await fetchAPI(INVITE_USERS, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const getInvitationData = async (token: string) =>
  await fetchAPI<InvitationData & Response>(`${INVITATION}${token}/`);

export const invitationService = {
  sendInvitation,
  getInvitationData,
};

export default invitationService;
