import {
  LOGIN_URL,
  REFRESH_URL,
  LOGUT_URL,
  RESET_PASSWORD_REQUEST_URL,
  RESET_PASSWORD_URL,
  ACCEPT_INVITATION,
  CHANGE_PASSWORD_URL,
} from '../urls/api';
import fetchApi from './fetchApi';
import { LoginFormProps } from 'components/User/LoginForm/LoginForm.d';

const jsonContentTypeHeader = { 'Content-Type': 'application/json' };

export type RequestResetPassword = {
  email: string;
};

export type ResetPassword = {
  password: string;
  token: string;
};

export type ChangePassword = {
  old_password: string;
  new_password: string;
};

export type SignUpData = {
  password: string;
  confirm_password: string;
  token: string;
  company: string;
  position: string;
};

export interface LoginResponse extends Response {
  access: string;
  refresh: string;
  detail: string;
  message?: string;
}

export interface RefreshResponse extends Response {
  access: string;
  refresh: string;
}

export const login = async (data: LoginFormProps) => {
  const response = await fetchApi<LoginResponse>(LOGIN_URL, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const logout = async () => {
  const refreshToken = localStorage.getItem('refresh');
  const parsedToken = refreshToken && JSON.parse(refreshToken);
  const response = await fetchApi(LOGUT_URL, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify({ refresh: parsedToken }),
  });
  return response;
};

export const refresh = async (refreshToken: string) => {
  const response = await fetchApi<RefreshResponse>(REFRESH_URL, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify({ refresh: refreshToken }),
  });
  return response;
};

export const requestResetPassword = async (data: RequestResetPassword) => {
  const response = await fetchApi(RESET_PASSWORD_REQUEST_URL, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const resetPassword = async (data: ResetPassword) => {
  const response = await fetchApi(RESET_PASSWORD_URL, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const changePassword = async (data: ChangePassword) => {
  const response = await fetchApi(CHANGE_PASSWORD_URL, {
    method: 'PATCH',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const signUp = async (data: SignUpData) => {
  const response = await fetchApi(ACCEPT_INVITATION, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const authService = {
  login,
  logout,
  refresh,
  requestResetPassword,
  resetPassword,
  signUp,
};

export default authService;
