import * as Yup from 'yup';

export const savePresetSchema = Yup.object({
  name: Yup.string()
    .required('Bitte geben Sie den Namen ein.')
    .test(
      'empty-check',
      'Der Name darf nicht leer sein.',
      (name) => name.trim().length !== 0,
    ),
});
