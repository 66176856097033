import { UserProfileResponse } from 'types/User.d';
import { PROFILE_URL } from 'urls/api';
import fetchApi from './fetchApi';
import { ProfileFormProps } from 'components/User/ProfileForm/ProfileForm.d';

type UserProfileData = {
  name: string;
  last_name: string;
  company: string;
  position: string;
  phone_number: string;
};

export const fetchProfile = () =>
  fetchApi<UserProfileResponse & Response>(PROFILE_URL);

export const fetchUpdateProfile = async (data: UserProfileData) => {
  return await fetchApi(PROFILE_URL, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};
