import { FC, useEffect, useRef, useState } from 'react';
import { ChipArticleFiltersProps } from './ChipArticleFilters.d';
import { Box } from 'components/common/Box';
import { SelectableFilterChip } from 'components/common/Chip/SelectableFilterChip';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const ChipArticleFilters: FC<ChipArticleFiltersProps> = ({
  id,
  options,
  maxSelected,
}) => {
  const prevSelectedRef = useRef<string[]>([]);
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValue = searchParams.getAll(id);
  const [selectedChips, setSelectedChips] = useState<string[]>(filterValue);

  const handleChipClick = (value: string) => {
    if (selectedChips.includes(value)) {
      setSelectedChips(
        selectedChips.filter(
          (chip) => chip.toLowerCase() !== value.toLowerCase(),
        ),
      );
    } else if (!maxSelected || selectedChips.length < maxSelected) {
      setSelectedChips([...selectedChips, value]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.delete(id);

    if (!!selectedChips.length) {
      selectedChips.forEach((chip) => params.append(id, chip.toLowerCase()));
    }

    const prevSelected = prevSelectedRef.current;
    const isFilterChanged =
      selectedChips.length !== prevSelected.length ||
      selectedChips.some((item, index) => item !== prevSelected[index]);

    if (isFilterChanged) {
      params.delete('page');
    }

    customNavigate(pathname + '?' + params.toString());
    prevSelectedRef.current = selectedChips;
  }, [selectedChips, id]);

  useEffect(() => {
    const newSelectedChips = searchParams.getAll(id);
    setSelectedChips(newSelectedChips);
  }, [searchParams, id]);

  return (
    <Box display="flex" gap={1.5} alignItems="center" flexWrap="wrap">
      {options.map(({ value }) => (
        <SelectableFilterChip
          key={value}
          label="Artikel"
          selected={selectedChips.includes(value)}
          article={value}
          onClick={() => handleChipClick(value)}
          disabled={
            selectedChips.length === maxSelected &&
            !selectedChips.includes(value)
          }
        />
      ))}
    </Box>
  );
};
