import {
  ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS,
  ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING,
  CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS,
  CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING,
  FAVORITES_LIST,
  FEEDBACK_PRIVATE_MARKETS,
  FEEDBACK_SMART_BENCHMARKING,
  PRIVATE_MARKETS,
  REQUEST_REPORT_PRIVATE_MARKETS,
  REQUEST_REPORT_SMART_BENCHMARKING,
  REQUEST_VOTE_SMART_BENCHMARKING,
  SMART_BENCHMARKING,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  PrivateMarketsFunds,
  SmartFundBenchmarkingItemProps,
} from 'types/Modules.d';
import { capitalizeWords } from 'utils/common';

export const fetchUpdateFundListStatusPrivateMarkets = (id: string) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const sendRequestReportPrivateMarkets = (id: string) =>
  fetchAPI(REQUEST_REPORT_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistPrivateMarkets = (ids: string[]) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

export const fetchUpdateFundListStatusSmartBenchmarking = (id: string) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestReportSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_REPORT_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestVoteSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_VOTE_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistSmartBenchmarking = (ids: string[]) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

interface FeedbackWithRelevanceLevelRequest {
  id: string;
  text: string;
  relevance_level: string;
}

export const fetchSendFundFeedbackPrivateMarkets = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_PRIVATE_MARKETS(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

export const fetchSendFundFeedbackSmartBenchmarking = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_SMART_BENCHMARKING(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

interface PrivateMarketsResponse extends Response {
  results: PrivateMarketsFunds;
  count: number;
}

interface PrivateMarketsRequest {
  page?: number;
  page_size?: number;
  vote?: string;
  risk?: string;
  credibility_min?: string;
  credibility_max?: string;
  minimum_investment_min?: string;
  minimum_investment_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

export const fetchPrivateMarkets = ({
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const fetchPrivateMarkets = fetchAPI<PrivateMarketsResponse>(
    `${PRIVATE_MARKETS}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${risk ? `&risk=${risk}` : ''}${
      minimum_investment_min
        ? `&minimum_investment_min=${minimum_investment_min}`
        : ''
    }${
      minimum_investment_max
        ? `&minimum_investment_max=${minimum_investment_max}`
        : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPrivateMarkets;
};

export const fetchFavoritesPrivateMarkets = ({
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const fetchPrivateMarkets = fetchAPI<PrivateMarketsResponse>(
    `${FAVORITES_LIST}?module=private_market${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${risk ? `&risk=${risk}` : ''}${
      minimum_investment_min
        ? `&minimum_investment_min=${minimum_investment_min}`
        : ''
    }${
      minimum_investment_max
        ? `&minimum_investment_max=${minimum_investment_max}`
        : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPrivateMarkets;
};

interface SmartBenchmarkingRequest {
  [key: string]: string | number | string[];
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarking = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${SMART_BENCHMARKING}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}${
      Object.keys(restValues).length
        ? `&${Object.entries(restValues)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value
                    .map(
                      (v) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                          capitalizeWords(String(v)),
                        )}`,
                    )
                    .join('&')
                : `${encodeURIComponent(key)}=${encodeURIComponent(
                    capitalizeWords(String(value)),
                  )}`,
            )
            .join('&')}`
        : ''
    }`,
  );
  return fetchSFB;
};

export const fetchFavoritesSmartBenchmarking = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${FAVORITES_LIST}?module=smart_benchmarking${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}${
      Object.keys(restValues).length
        ? `&${Object.entries(restValues)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value
                    .map(
                      (v) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                          capitalizeWords(String(v)),
                        )}`,
                    )
                    .join('&')
                : `${encodeURIComponent(key)}=${encodeURIComponent(
                    capitalizeWords(String(value)),
                  )}`,
            )
            .join('&')}`
        : ''
    }`,
  );
  return fetchSFB;
};
