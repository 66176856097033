import { createContext, useContext, ReactNode, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type NavigationContextType = {
  customNavigate: (path: string) => void;
};

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

export const NavigationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const customNavigate = (path: string) => {
    const currentPath = location.pathname + location.search;

    const historyStack = JSON.parse(
      sessionStorage.getItem('historyStack') || '[]',
    );

    historyStack.push(currentPath);
    sessionStorage.setItem('historyStack', JSON.stringify(historyStack));

    navigate(path);
  };

  return (
    <NavigationContext.Provider value={{ customNavigate }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
