import { FC } from 'react';
import { ESGActionsProps } from './ESGActions.d';
import { Box } from 'components/common/Box';
import {
  StyledHead,
  StyledInfoIcon,
  StyledContainer,
} from './ESGActions.styled';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { HalfDoughnutChart } from '../HalfDoughnutChart';
import { Typography } from 'components/common/Typography';
import { hexToRGB } from 'utils/common';
import { tooltipInfo } from './config';

export const ESGActions: FC<ESGActionsProps> = ({
  actionScoreFund,
  actionScoreFundCompany,
}) => {
  return (
    <Box>
      <StyledHead>
        ESG Actions
        <CustomTooltip title={tooltipInfo} placement="top">
          <StyledInfoIcon stroke={hexToRGB(COLORS.success.dark, '0.5')} />
        </CustomTooltip>
      </StyledHead>
      <Box>
        <StyledContainer px={3} py={4}>
          <Typography
            variant="body"
            weight="bold"
            color={COLORS.accent.secondary}
            textAlign="center"
          >
            ESG Action Score Fonds
          </Typography>
          <Box
            pt={4.5}
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {actionScoreFund ? (
              <HalfDoughnutChart value={actionScoreFund} />
            ) : (
              <Typography variant="body">Keine Daten gefunden</Typography>
            )}
          </Box>
        </StyledContainer>
        <StyledContainer p={3}>
          <Typography
            variant="body"
            weight="bold"
            color={COLORS.accent.secondary}
            textAlign="center"
          >
            ESG Action Score Fondsgesellschaft
          </Typography>
          <Box
            pt={4.5}
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {actionScoreFundCompany ? (
              <HalfDoughnutChart value={actionScoreFundCompany} />
            ) : (
              <Typography variant="body">Keine Daten gefunden</Typography>
            )}
          </Box>
        </StyledContainer>
      </Box>
    </Box>
  );
};
