import styled from 'styled-components';
import { DividerProps } from './Divider.d';
import { SIZES } from 'theme/sizes';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';

export const StyledLine = styled.hr<DividerProps>`
  width: 100%;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: ${COLORS.stroke.main};
  margin: 0;
  ${({ m }) => (m || m === 0) && `margin: ${SIZES.spacing(m)};`}
  ${({ mx }) =>
    (mx || mx === 0) &&
    `margin-left: ${SIZES.spacing(mx)}; margin-right: ${SIZES.spacing(mx)};`}
  ${({ my }) =>
    (my || my === 0) &&
    `margin-top: ${SIZES.spacing(my)}; margin-bottom: ${SIZES.spacing(my)};`}
  ${({ mt }) => (mt || mt === 0) && `margin-top: ${SIZES.spacing(mt)};`}
  ${({ mb }) => (mb || mb === 0) && `margin-bottom: ${SIZES.spacing(mb)};`}
  ${({ ml }) => (ml || ml === 0) && `margin-left: ${SIZES.spacing(ml)};`}
  ${({ mr }) => (mr || mr === 0) && `margin-right: ${SIZES.spacing(mr)};`}
`;

export const StyledContent = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
`;
