import { FC, useEffect, useState } from 'react';
import {
  ButtonsContainer,
  StyledContainer,
  StyledHeadline,
  StyledInnerContainer,
  StyledLeftContainer,
  StyledLogo,
  StyledLogoContainer,
  StyledRightContainer,
} from './SignUp.styled';
import { useSearchParams } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Divider } from 'components/common/Divider';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { SignUpForm } from 'components/User/SignUpForm';
import { InvitedBy } from 'components/User/InvitedBy';
import { useWindowSize } from 'hooks/useWindowSize';
import { getInvitationData } from 'services/Invitation';
import { InvitationData } from 'types/Invitation.d';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const SignUp: FC = () => {
  const { customNavigate } = useNavigation();
  const { isTablet } = useWindowSize();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const [invitationData, setInvitationData] = useState<InvitationData | null>(
    null,
  );

  useEffect(() => {
    if (token.length === 0) {
      customNavigate(AppPaths.login);
    }
  }, [searchParams]);

  const getData = async () => {
    const response = await getInvitationData(token);
    if (response.ok) {
      const data = response.parsedBody;
      setInvitationData(data);
    }
  };

  useEffect(() => {
    getData();
  }, [token]);

  return (
    <StyledContainer>
      <StyledInnerContainer
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <StyledLogoContainer onClick={() => customNavigate(AppPaths.main)}>
          <StyledLogo />
        </StyledLogoContainer>
        <StyledLeftContainer>
          <StyledHeadline variant="h2">
            Anmeldung zur FondsConsult Research Plattform abschließen
          </StyledHeadline>
          {(invitationData?.inviter.name ||
            invitationData?.inviter.last_name) && (
            <InvitedBy
              name={invitationData.inviter.name}
              lastName={invitationData.inviter.last_name}
              company={invitationData.inviter.company}
            />
          )}
          <SignUpForm
            invitedEmail={invitationData?.email}
            invitedName={invitationData?.name}
            invitedLastName={invitationData?.last_name}
            token={token}
          />
          <Divider my={isTablet ? 3 : 4} content="Oder" />
          <ButtonsContainer
            display="flex"
            justifyContent="center"
            gap={3}
            mb={3}
          >
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => customNavigate(AppPaths.login)}
            >
              Anmelden
            </Button>
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => customNavigate(AppPaths.contuctUs)}
            >
              Hilfe erhalten
            </Button>
          </ButtonsContainer>
        </StyledLeftContainer>
      </StyledInnerContainer>
      {!isTablet && <StyledRightContainer />}
    </StyledContainer>
  );
};
