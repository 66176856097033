import { css } from 'styled-components';
import { FieldBasic } from './common';
import { pxToRem } from 'utils/common';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledFieldBasic = css<FieldBasic>`
  ${({ error }) => css`
    box-sizing: border-box;
    border: ${error
      ? `1px solid ${COLORS.error.main}`
      : `1px solid ${COLORS.stroke.main}`};
    border-radius: 4px;
    outline: none;
    padding: ${pxToRem(16)} ${pxToRem(16)};
    width: 100%;
    font-size: ${SIZES.typography.input};
    line-height: 20px;
    color: ${COLORS.typography.main};
    font-family: 'Inter', sans-serif;
    height: 49px;
    transition-duration: 0.2s;

    &::placeholder {
      color: ${COLORS.typography.caption};
    }
    &:enabled {
      &:hover {
        border: 1px solid ${COLORS.typography.caption};
      }

      &:focus {
        border: 4px solid ${COLORS.stroke.border};
      }
    }

    &:disabled {
      background-color: ${COLORS.accent.disabled};
      color: ${COLORS.stroke.main};
    }

    &:read-only {
      color: ${COLORS.typography.description};
      background-color: ${COLORS.stroke.primary};
      pointer-events: none;
    }
  `}
`;
