import { FC } from 'react';
import { ESGFiltersModalProps } from './ESGFiltersModal.d';
import { StyledContainer, StyledFilterItem } from './ESGFiltersModal.styled';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SlideOutFilterModal } from 'components/common/Modals/SlideOutFilterModal';
import { RangeFilters } from '../RangeFilters';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const ESGFiltersModal: FC<ESGFiltersModalProps> = ({
  filters,
  isShow,
  clearFilters,
  closeModal,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { customNavigate } = useNavigation();

  const handleClearAllFilters = () => {
    const newSearchParams = new URLSearchParams();
    filters.forEach(({ id }) => newSearchParams.delete(id.toString()));
    setSearchParams(newSearchParams);
    clearFilters();
    closeModal();
    customNavigate(pathname + '?' + newSearchParams.toString());
  };

  return (
    <SlideOutFilterModal
      isShown={isShow}
      closeModal={closeModal}
      clearFilters={handleClearAllFilters}
      applyFilters={closeModal}
    >
      <StyledContainer>
        {filters.map(
          ({ id, label, options }) =>
            options && (
              <StyledFilterItem key={id}>
                <RangeFilters id={id} options={options} label={label} />
              </StyledFilterItem>
            ),
        )}
      </StyledContainer>
    </SlideOutFilterModal>
  );
};
