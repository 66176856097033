import * as Yup from 'yup';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;

export const profileSchema = Yup.object({
  first_name: Yup.string()
    .required('Bitte geben Sie ein Vorname ein.')
    .test(
      'empty-check',
      'Das Vorname darf nicht leer sein.',
      (name) => name.trim().length !== 0,
    ),
  last_name: Yup.string()
    .required('Bitte geben Sie ein Nachname ein.')
    .test(
      'empty-check',
      'Das Nachname darf nicht leer sein.',
      (last_name) => last_name.trim().length !== 0,
    ),
  company: Yup.string()
    .required('Bitte geben Sie ein Unternehmen ein.')
    .test(
      'empty-check',
      'Das Unternehmen darf nicht leer sein.',
      (company) => company.trim().length !== 0,
    ),
  position: Yup.string()
    .required('Bitte geben Sie eine Position ein.')
    .test(
      'empty-check',
      'Die Position darf nicht leer sein.',
      (position) => position.trim().length !== 0,
    ),
  phone_number: Yup.string()
    .required('Bitte geben Sie eine Telefonnummer ein.')
    .matches(phoneRegExp, 'Telefonnummer ist ungültig.')
    .test(
      'empty-check',
      'Die Rufnummer darf nicht leer sein.',
      (body) => body.trim().length !== 0,
    ),
  phone_number_code: Yup.string(),
});
