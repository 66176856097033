import { FC } from 'react';
import { ExpandableRowProps } from './ExpandableRow.d';
import { Box, Collapse, TableRow } from '@mui/material';
import {
  StyledExpandableRowTableCell,
  StyledHeadTableCell,
  StyledHeadTableCellContainer,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellContainer,
  StyledTable,
} from './ExpandableRow.styled';
import { addDotsToString } from 'utils/common';
import { InnerExpandableRow } from '../InnerExpandableRow';
import { getRowColor } from './utils';
import { COLORS } from 'theme/colors';

const MAX_LENGTH = 95;

export const ExpandableRow: FC<ExpandableRowProps> = ({
  isDifferencesHighlighted,
  isDifferencesHighlightable,
  open,
  data,
  row,
  columns,
}) => {
  const pinnedColumn = columns[0];

  return (
    <TableRow>
      <StyledExpandableRowTableCell colSpan={columns.length + 2}>
        <Collapse in={open[row]} timeout="auto" unmountOnExit>
          <Box>
            <StyledTable>
              <StyledTableBody>
                {data.map((insideRow) => (
                  <>
                    {insideRow.data && insideRow.data.length > 0 ? (
                      <InnerExpandableRow
                        insideRow={insideRow}
                        isDifferencesHighlighted={isDifferencesHighlighted}
                        isDifferencesHighlightable={isDifferencesHighlightable}
                        columns={columns}
                      />
                    ) : (
                      <TableRow key={insideRow.label}>
                        <StyledHeadTableCell
                          component="th"
                          scope="row"
                          align="left"
                          width="244px"
                        >
                          <StyledHeadTableCellContainer>
                            {insideRow.label}
                          </StyledHeadTableCellContainer>
                        </StyledHeadTableCell>
                        {columns.map((item, idx) => (
                          <StyledTableCell
                            key={`${item[insideRow.id]}-${idx}`}
                            align="left"
                            width="325px"
                          >
                            <StyledTableCellContainer
                              color={
                                isDifferencesHighlighted
                                  ? getRowColor(
                                      item[insideRow.id],
                                      insideRow.id,
                                      pinnedColumn,
                                      insideRow.options,
                                    )
                                  : COLORS.background.secondary
                              }
                            >
                              {item[insideRow.id].length >= MAX_LENGTH
                                ? addDotsToString(
                                    item[insideRow.id],
                                    MAX_LENGTH,
                                  )
                                : item[insideRow.id]}
                            </StyledTableCellContainer>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    )}
                  </>
                ))}
              </StyledTableBody>
            </StyledTable>
          </Box>
        </Collapse>
      </StyledExpandableRowTableCell>
    </TableRow>
  );
};
