import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  margin: 32px 20px;
  width: 90%;

  ${SIZES.media.phone} {
    width: 73%;
    margin: 25px auto;
  }

  ${SIZES.media.tablet} {
    width: 43%;
  }

  ${SIZES.media.desktop} {
    width: 33%;
  }
`;
