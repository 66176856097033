import React, { useState } from 'react';
import { ButtonsContainer, StyledContainer } from './Login.styled';
import { LoginForm } from 'components/User/LoginForm/LoginForm';
import { Divider } from 'components/common/Divider/Divider';
import { Button } from 'components/common/Buttons/Button';
import {
  ButtonSize,
  ButtonVariant,
} from 'components/common/Buttons/Button/Button.enums';
import { AppPaths } from 'urls/frontend';
import { StartView } from 'components/User/StartView/StartView';
import { Box } from 'components/common/Box';
import { LoginFormProps } from 'components/User/LoginForm/LoginForm.d';
import { useAuth } from 'context/Auth';
import { RegistrationRequestModal } from 'components/common/Modals/RegistrationRequestModal/RegistrationRequestModal';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const Login: React.FC = () => {
  const { customNavigate } = useNavigation();
  const auth = useAuth();
  const [isRegistrationRequestModalOpen, setIsRegistrationRequestModalOpen] =
    useState(false);

  const onHandleLoginClick = async (data: LoginFormProps) => {
    await auth?.login(data);
  };

  const onHandleGetHelpClick = () => {
    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/contact/`;
  };

  return (
    <StartView headline="Anmelden">
      <RegistrationRequestModal
        isShown={isRegistrationRequestModalOpen}
        toggle={() => setIsRegistrationRequestModalOpen(false)}
      />
      <StyledContainer>
        <LoginForm onSubmit={onHandleLoginClick} />
        <Divider my={4} content="Oder" />
        <ButtonsContainer display="flex" justifyContent="center" gap={3}>
          <Button
            fullWidth
            variant={ButtonVariant.OUTLINED}
            onClick={() => setIsRegistrationRequestModalOpen(true)}
          >
            Konto erstellen
          </Button>
          <Button
            fullWidth
            variant={ButtonVariant.OUTLINED}
            onClick={onHandleGetHelpClick}
          >
            Hilfe erhalten
          </Button>
        </ButtonsContainer>
        <Box display="flex" justifyContent="center">
          <Button
            size={ButtonSize.SMALL}
            variant={ButtonVariant.TEXT}
            onClick={() => customNavigate(AppPaths.passwordResetRequest)}
          >
            Passwort zurücksetzen
          </Button>
        </Box>
      </StyledContainer>
    </StartView>
  );
};
