import { FC, useEffect, useState } from 'react';
import { RangeFiltersProps } from './RangeFilters.d';
import { RangeSlider } from 'components/common/RangeSlider';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const RangeFilters: FC<RangeFiltersProps> = ({ options, id, label }) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, _] = useSearchParams();
  const [min, max] = options;
  const [value, setValue] = useState<number[] | undefined>(undefined);

  const handleRangeChange = (values: number[]) => {
    setValue(values);
  };

  useEffect(() => {
    if (value && !!value.length) {
      const params = new URLSearchParams(search);
      const currentFilterValue = params.get(id);
      const newFilterValue = value.join('-');

      params.delete(id);
      params.append(id, newFilterValue);
      if (currentFilterValue !== newFilterValue) {
        params.delete('page');
      }
      customNavigate(pathname + '?' + params.toString());
    }
  }, [value, id]);

  useEffect(() => {
    let newValue;
    const filterValue = searchParams.get(id);
    if (filterValue) {
      newValue = filterValue.split('-').map(Number);
    } else {
      newValue = undefined;
    }
    setValue(newValue);
  }, [searchParams, id]);

  return (
    <Box style={{ width: '398px' }}>
      <RangeSlider
        label={label || ''}
        min={Number(min.value)}
        max={Number(max.value)}
        step={5}
        currentValues={value}
        onChange={(values) => handleRangeChange(values)}
      />
    </Box>
  );
};
