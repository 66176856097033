import { FC, useMemo, useState } from 'react';
import { StartView } from 'components/User/StartView/StartView';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { useLocation } from 'react-router-dom';
import { AppPaths } from '../../../../urls/frontend';
import { ReactComponent as FormSendingConfirmIcon } from 'assets/Icons/forgot-password.svg';
import { requestResetPassword } from 'services/Auth';
import { raiseToast } from 'components/common/Toast/raiseToast';
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledDescription,
  StyledTypography,
} from './ResetPasswordLinkSent.styled';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const ResetPasswordLinkSent: FC = () => {
  const { customNavigate } = useNavigation();
  const query = useQuery();
  const email = query.get('email');
  const [resendClicked, setResendClicked] = useState(false);

  const resend = async () => {
    if (email) {
      const response = await requestResetPassword({ email });
      if (response.ok) {
        raiseToast.success(
          'Wir haben Ihnen eine neue E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.',
        );
        setResendClicked(true);
      }
    }
  };

  return (
    <StartView
      headline="Überprüfen Sie Ihren Posteingang"
      icon={<FormSendingConfirmIcon />}
    >
      <StyledContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <StyledDescription variant="body">
          Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres
          Passworts gesendet
        </StyledDescription>
        <Button fullWidth onClick={() => customNavigate(AppPaths.login)}>
          Zurück zum Login
        </Button>
      </StyledContainer>
      <StyledButtonsContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={0.5}
      >
        <StyledTypography variant="body">
          E-Mail nicht erhalten?
        </StyledTypography>
        <Button
          variant={ButtonVariant.TEXT}
          onClick={resend}
          disabled={resendClicked}
        >
          Erneut senden
        </Button>
      </StyledButtonsContainer>
    </StartView>
  );
};
