import { useEffect, useState } from 'react';
import {
  StyledDistanseRow,
  StyledTableHeader,
  StyledTableRow,
} from './Row.styled';
import { RowProps } from './Row.d';
import { ExpandablePrivateMarketsRealEstateRow } from 'components/Modules/Tables/PrivateMarketsRealEstateTable/ExpandablePrivateMarketsRealEstateRow/ExpandablePrivateMarketsRealEstateRow';
import { SelectCell } from './SelectCell';
import { ArrowCell } from './ArrowCell/ArrowCell';
import {
  CreatedRowPrivateMarkets,
  CreatedRowSmartFundBenchmarking,
  PrivateMarkets,
  SmartFundBenchmarkingColumn,
} from '../CollapseTable.d';
import { ExpandableSmartFundBenchmarkingRow } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/ExpandableSmartFundBenchmarkingRow';
import { useSearchParams } from 'react-router-dom';

export const Row = ({
  tableData,
  selectedRows,
  setSelectedRows,
  updatePositions,
}: RowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isChecked = selectedRows.includes(tableData.row.id);
  const [searchParams] = useSearchParams();
  const tableTab = searchParams.get('table-tab') || '';
  const collapseTableTab = searchParams.get('collapse-table-tab') || '';
  const id = searchParams.get('id') || '';
  const peerGroup = searchParams.get('peer_group_id') || '';
  const sortModel = searchParams.get('sort') || '';

  const updateSelectedRows = (e: React.BaseSyntheticEvent, id: string) => {
    e.stopPropagation();
    const index = selectedRows.indexOf(id);
    const newSelectedRows: string[] =
      index === -1
        ? [...selectedRows, id]
        : selectedRows.filter((_, i) => i !== index);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    updatePositions && updatePositions();
  }, [selectedRows, tableTab, collapseTableTab, id, peerGroup, sortModel]);

  return (
    <>
      <StyledTableRow
        id={`id${tableData.row.id}`}
        key={tableData.row.id}
        selected={isChecked}
        onClick={() => setIsOpen((prevSetIsOpen) => !prevSetIsOpen)}
        style={{
          ...(tableData.type === 'smartFundBenchmarking' ||
          tableData.type === 'recommendations-for-action'
            ? { height: 100 }
            : {}),
        }}
      >
        <ArrowCell {...{ isOpen, isChecked, setIsOpen }} />
        <SelectCell
          isChecked={isChecked}
          isOpen={isOpen}
          setIsChecked={(e) => updateSelectedRows(e, tableData.row.id)}
        />
        {tableData.columns.map((column, index) => {
          return (
            <StyledTableHeader
              isOpen={isOpen}
              isChecked={isChecked}
              align={column.cellAlign || 'center'}
              dottedLeftBorder={column.dottedLeftBorder}
              isHighlighted={column.isHighlighted || false}
              width={column.maxWidth}
              onClick={(e) =>
                column.onClick &&
                column.onClick(
                  e,
                  tableData?.row as CreatedRowPrivateMarkets &
                    CreatedRowSmartFundBenchmarking,
                )
              }
              isClickable={!!column.onClick}
            >
              {column.renderCell
                ? column.renderCell({
                    row: tableData?.row as CreatedRowPrivateMarkets &
                      CreatedRowSmartFundBenchmarking,
                    value:
                      tableData?.row[
                        column.id as keyof CreatedRowPrivateMarkets &
                          keyof CreatedRowSmartFundBenchmarking
                      ],
                  })
                : tableData?.row[
                    column.id as keyof CreatedRowPrivateMarkets &
                      keyof CreatedRowSmartFundBenchmarking
                  ]}
            </StyledTableHeader>
          );
        })}
      </StyledTableRow>
      {tableData.type === 'privateMarkets' && (
        <ExpandablePrivateMarketsRealEstateRow
          rowData={tableData.row as CreatedRowPrivateMarkets}
          columns={tableData.columns as PrivateMarkets[]}
          isOpen={isOpen}
          key={tableData.row.id}
        />
      )}
      {(tableData.type === 'smartFundBenchmarking' ||
        tableData.type === 'recommendations-for-action') && (
        <ExpandableSmartFundBenchmarkingRow
          rowData={tableData.row as CreatedRowSmartFundBenchmarking}
          columns={tableData.columns as SmartFundBenchmarkingColumn[]}
          isOpen={isOpen}
          key={tableData.row.id}
          updatePositions={updatePositions}
        />
      )}
      <StyledDistanseRow />
    </>
  );
};
