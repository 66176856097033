import { ResetPasswordForm } from 'components/User/ResetPasswordForm';
import { ResetPasswordFields } from 'components/User/ResetPasswordForm/ResetPasswordForm.d';
import { StartView } from 'components/User/StartView';
import { FC, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from 'services/Auth';
import { AppPaths } from 'urls/frontend';
import { StyledContainer } from './ChangePassword.styled';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const ChangePassword: FC = () => {
  const { customNavigate } = useNavigation();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!token) customNavigate(AppPaths.main);
  }, [customNavigate, token]);

  const changePassword = async (data: ResetPasswordFields) => {
    const { password } = data;
    if (token) {
      const response = await resetPassword({ password, token });
      if (response.ok) {
        customNavigate(AppPaths.resetPasswordConfirmed);
      }
    }
  };

  return (
    <StartView headline="Create New Password">
      <StyledContainer>
        <ResetPasswordForm onSubmit={changePassword} />
      </StyledContainer>
    </StartView>
  );
};
