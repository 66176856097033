import {
  StyledMenuItem,
  StyledSpan,
  StyledInputContainer,
  StyledSelect,
  StyledInput,
  StyledFormControl,
  StyledMenuItemContainer,
  StyledPlaceholder,
  HiddenFirstMenuItem,
} from './MyFiltersButton.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow.svg';
import { Option, SelectorProps } from './MyFiltersButton.d';
import { COLORS } from 'theme/colors';
import { FC, useState } from 'react';
import { addDotsToString } from 'utils/common';

const MAX_LENGTH = 25;

export const MyFiltersButton: FC<SelectorProps> = ({
  label = '',
  value,
  name = '',
  action,
  options,
  required = false,
  disabled,
  placeholder,
  variant = 'standart',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledInputContainer isOpen={isOpen}>
      <StyledFormControl variant="outlined">
        {placeholder && value == null && (
          <StyledPlaceholder>{placeholder}</StyledPlaceholder>
        )}
        <StyledSelect
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          label={label}
          variant="outlined"
          name={name}
          disabled={disabled}
          labelId={label}
          value={value || ''}
          onChange={action}
          IconComponent={(props) => (
            <ArrowIcon {...props} stroke={COLORS.stroke.secondary} />
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '4px',
                borderRadius: '8px',
                width: 185,
              },
            },
            MenuListProps: {
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          }}
          input={<StyledInput value={value ? value.value : ''} />}
          required={required}
          renderValue={(valueP: any) => (
            <span>
              <StyledSpan isOpen={isOpen}>
                {variant === 'filter'
                  ? placeholder
                  : valueP.name.length >= MAX_LENGTH
                  ? addDotsToString(valueP.name, MAX_LENGTH)
                  : valueP.name}
              </StyledSpan>
            </span>
          )}
          {...props}
        >
          {/* Without this line first item becomes focused after openning menu */}
          {/* https://github.com/mui/material-ui/issues/23747 */}
          <HiddenFirstMenuItem key="hidden" />
          {options.map((option: Option, idx: number) => (
            <StyledMenuItemContainer
              value={option as any}
              key={idx}
              isSelected={option.value === value?.value}
            >
              <StyledMenuItem>{option.name}</StyledMenuItem>
            </StyledMenuItemContainer>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </StyledInputContainer>
  );
};
