import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.saveFiltersPreset};
  }
`;
