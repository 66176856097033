import { FC } from 'react';
import { StartView } from 'components/User/StartView/StartView';
import { Typography } from 'components/common/Typography/Typography';
import { COLORS } from 'theme/colors';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Buttons/Button';
import { Box } from 'components/common/Box';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { AppPaths } from 'urls/frontend';
import { BackButton } from 'components/common/Buttons/BackButton/BackButton';
import { passwordResetRequestSchema } from './validators';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordResetRequestForm } from './PasswordResetRequest.d';
import { isValidForm } from 'utils/isValidForm';
import { requestResetPassword } from 'services/Auth';
import {
  StyledContainer,
  StyledDescription,
  StyledFormContainer,
} from './PasswordResetRequest.styled';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const PasswordResetRequest: FC = () => {
  const { customNavigate } = useNavigation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordResetRequestForm>({
    resolver: yupResolver(passwordResetRequestSchema),
    mode: 'onBlur',
  });

  const email = watch('email');

  const onSubmitForm = async (data: PasswordResetRequestForm) => {
    const response = await requestResetPassword(data);
    if (response.ok) {
      customNavigate(`${AppPaths.resetPasswordLinkSent}?email=${email}`);
    }
  };

  return (
    <StartView headline="Setzen Sie Ihr Passwort zurück">
      <StyledContainer display="flex" flexDirection="column">
        <StyledDescription
          variant="body"
          color={COLORS.accent.secondary}
          textAlign="center"
        >
          Geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist,
          und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts
        </StyledDescription>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <StyledFormContainer display="flex" flexDirection="column">
            <Input
              placeholder="Email"
              {...register('email')}
              error={!isValidForm(errors) && errors.email?.message}
            />
            <Button fullWidth type="submit">
              Passwort zurücksetzen
            </Button>
          </StyledFormContainer>
        </form>
        <Box display="flex" justifyContent="center">
          <BackButton
            variant={ButtonVariant.TEXT}
            onClick={() => customNavigate(AppPaths.login)}
          >
            Zurück zum Login
          </BackButton>
        </Box>
      </StyledContainer>
    </StartView>
  );
};
