import {
  StyledContent,
  StyledHeader,
  StyledHeadline,
  StyledHeadlineContainer,
  StyledLogo,
} from './StartView.styled';
import { Box } from 'components/common/Box';
import { FC } from 'react';
import { StartViewProps } from './StartView.d';
import { Link, useLocation } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';

export const StartView: FC<StartViewProps> = ({ headline, icon, children }) => {
  const { pathname } = useLocation();
  const isHomePage = pathname === AppPaths.main || pathname === AppPaths.login;
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL || AppPaths.main;

  const onHandleGoToWebsite = () => {
    window.location.href = websiteUrl;
  };

  return (
    <>
      <StyledHeader>
        <Link
          to={isHomePage ? websiteUrl : AppPaths.main}
          onClick={isHomePage ? onHandleGoToWebsite : undefined}
        >
          <StyledLogo />
        </Link>
      </StyledHeader>
      <StyledContent>
        {!!icon && (
          <Box display="flex" justifyContent="center" mb={2} mt={12.5}>
            {icon}
          </Box>
        )}
        {!!headline && (
          <StyledHeadlineContainer
            withIcon={!!icon}
            display="flex"
            justifyContent="center"
          >
            <StyledHeadline variant="h2" textAlign="center">
              {headline}
            </StyledHeadline>
          </StyledHeadlineContainer>
        )}
        <Box>{children}</Box>
      </StyledContent>
    </>
  );
};
