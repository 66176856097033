import styled, { css } from 'styled-components';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';
import { pxToRem } from 'utils/common';
import { SIZES } from 'theme/sizes';
import {
  MenuItem,
  Select,
  selectClasses,
  styled as MUIstyled,
  InputBase,
  inputBaseClasses,
} from '@mui/material';
import { StyledInputProps } from './PhoneInput.d';

export type FieldBasic = {
  error?: boolean;
};

export const StyledInput = styled.input<FieldBasic>`
  ${({ error }) => css`
    box-sizing: border-box;
    border: ${error
      ? `1px solid ${COLORS.error.main}`
      : `1px solid ${COLORS.stroke.primary}`};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: none;
    padding: ${pxToRem(16)} ${pxToRem(16)};
    width: 100%;
    font-size: ${SIZES.typography.input};
    line-height: 20px;
    color: ${COLORS.typography.main};
    font-family: 'Inter', sans-serif;
    height: 49px;
    transition-duration: 0.2s;

    &::placeholder {
      color: ${COLORS.typography.placeholder};
    }
    &:enabled {
      &:hover {
        border: 1px solid ${COLORS.typography.caption};
      }

      &:focus {
        border: 4px solid ${COLORS.stroke.border};
      }
    }

    &:disabled {
      background-color: ${COLORS.accent.disabled};
      color: ${COLORS.stroke.main};
    }
  `}
`;

export const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
`;

export const InputIcon = styled.span<StyledInputProps>`
  cursor: pointer;
  position: absolute;
  right: 16.5px;
  top: ${({ withLabel }) => (withLabel ? '41px' : '16.5px')};
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

export const StyledSelect = MUIstyled(Select)(() => ({
  [`& .${selectClasses.select}`]: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: COLORS.typography.main,
    fontSize: '14px',
    height: '40px',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 13,
    backgroundColor: 'white',
    '&:hover': {
      borderTop: `1px solid ${COLORS.typography.caption}`,
      borderBottom: `1px solid ${COLORS.typography.caption}`,
      borderLeft: `1px solid ${COLORS.typography.caption}`,
    },
  },
  [`& .${selectClasses.icon}`]: {
    right: 18,
    top: 'calc(50% - 7px)',
  },
}));

export const StyledSelectInput = MUIstyled(InputBase)(() => ({
  [`& .${inputBaseClasses.input}`]: {
    width: 36,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    border: `1px solid ${COLORS.stroke.primary}`,
    borderRight: 0,
    '&:focus': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

export const StyledMenuItem = MUIstyled(MenuItem)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  color: COLORS.typography.description,
  fontSize: '14px',
}));
