import * as Yup from 'yup';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;

export const contactUsSchema = Yup.object({
  sender_email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
    .required('Bitte geben Sie Ihre E-Mail-Adresse ein.'),
  sender_name: Yup.string()
    .required('Bitte geben Sie den Absendernamen ein.')
    .test(
      'empty-check',
      'Der Name darf nicht leer sein.',
      (name) => name.trim().length !== 0,
    ),
  subject: Yup.string()
    .required('Bitte geben Sie einen Betreff ein.')
    .test(
      'empty-check',
      'Der Betreff darf nicht leer sein.',
      (subject) => subject.trim().length !== 0,
    ),
  body: Yup.string()
    .required('Bitte geben Sie eine Nachricht ein.')
    .test(
      'empty-check',
      'Die Nachricht darf nicht leer sein.',
      (body) => body.trim().length !== 0,
    ),
  phone_number: Yup.string()
    .required('Bitte geben Sie eine Telefonnummer ein.')
    .matches(phoneRegExp, 'Telefonnummer ist ungültig.')
    .test(
      'empty-check',
      'Die Rufnummer darf nicht leer sein.',
      (body) => body.trim().length !== 0,
    ),
  phone_number_code: Yup.string()
    .required('')
    .test(
      'empty-check',
      'Die Telefonvorwahlnummer darf nicht leer sein.',
      (body) => body.trim().length !== 0,
    ),
  company: Yup.string()
    .required('Bitte geben Sie ein Unternehmen ein.')
    .test(
      'empty-check',
      'Das Unternehmen darf nicht leer sein.',
      (company) => company.trim().length !== 0,
    ),
  position: Yup.string()
    .required('Bitte geben Sie eine Position ein.')
    .test(
      'empty-check',
      'Die Position darf nicht leer sein.',
      (position) => position.trim().length !== 0,
    ),
    agreedToTerms: Yup.boolean()
    .oneOf(
      [true],
      'Die Allgemeinen Geschäftsbedingungen müssen akzeptiert werden.',
    )
    .required('Die Allgemeinen Geschäftsbedingungen müssen akzeptiert werden.'),
});
