import styled, { css } from 'styled-components';
import { LabelProps } from './Label.d';
import { COLORS } from 'theme/colors';

export const StyledLabel = styled.label<LabelProps>`
  ${({ error, disabled, required }) => css`
    position: relative;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: ${error ? COLORS.error.main : COLORS.typography.description};
    font-family: 'Inter', sans-serif;
    padding-bottom: 4px;
    ${disabled && 'opacity: 0.5'}

    ${required &&
    css`
      &::after {
        content: ' *';
        color: ${COLORS.error.main};
        position: absolute;
        top: -2px;
        padding-left: 10px;
      }
    `}
  `}
`;
