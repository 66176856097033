import styled from 'styled-components';
import { ItemProps } from './FormCheckbox.d';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';

export const StyledLabelContainer = styled.div`
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: auto;
`;

export const Item = styled.div<ItemProps>`
  cursor: pointer;
  display: flex;
  margin-right: 20px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;

  &:hover {
    label {
      box-shadow: 0px 3px 4px rgba(180, 180, 180, 0.2);
    }
  }
`;

export const CheckboxButtonLabel = styled.label`
  cursor: pointer;
  height: 18px;
  left: 0;
  margin-bottom: 3px;
  margin-top: 1px;
  position: absolute;
  width: 18px;
`;

export const CheckboxButton = styled.input`
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  margin: 0;
  margin-right: 8px;
  min-width: 18px;
  opacity: 0;
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    ` ~ ${CheckboxButtonLabel} {
        background: ${COLORS.stroke.main};
      }
      cursor: unset;
  `}
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

export const StyledTypography = styled(Typography)`
  line-height: 19px;
`;
