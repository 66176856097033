import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { ModuleLayoutProps } from './ModuleLayout.d';
import { SearchContainer, StyledContainer } from './ModuleLayout.styled';
import { Box } from 'components/common/Box';
import { Search } from 'components/common/Search';
import { FiltersButton } from 'components/common/Buttons/FiltersButton';
import { MyFiltersButton } from 'components/common/Buttons/MyFiltersButton';
import { SelectChangeEvent } from '@mui/material';
import { createQueryParams } from 'utils/common';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { useLocation } from 'react-router-dom';

export const ModuleLayout: FC<PropsWithChildren<ModuleLayoutProps>> = ({
  children,
  searchValue,
  searchPlaceholder,
  activeFiltersAmount,
  onSubmitSearch,
  onToggleFilters,
  withTabs = false,
  filterPresetOptions,
  presetFilterValue,
  onPresetFilterChange,
  ...props
}) => {
  const { pathname, search } = useLocation();
  const { customNavigate } = useNavigation();
  const [currentPresets, setCurrentPresets] = useState<string | null>(null);

  const transformObject = (input: {
    [key: string]: string | string[];
  }): {
    [key: string]: string | string[];
  } => {
    const result: { [key: string]: string | string[] } = {};

    for (const key in input) {
      if (key.endsWith('_max') || key.endsWith('_min')) {
        const baseKey = key.replace(/_(max|min)$/, '');
        const minKey = `${baseKey}_min`;
        const maxKey = `${baseKey}_max`;

        if (input[minKey] && input[maxKey]) {
          result[baseKey] = `${input[minKey]}-${input[maxKey]}`;
        }
      } else {
        result[key] = input[key];
      }
    }

    return result;
  };

  const handlePresetFilterChange = (event: SelectChangeEvent<any>) => {
    if (!!filterPresetOptions?.length) {
      const newValue = event.target.value.value;
      const [newFilter] = filterPresetOptions.filter(
        (item) => item.id === newValue,
      );
      if (newFilter.query_params && onPresetFilterChange) {
        const tranformedQueryParams = transformObject(newFilter.query_params);
        const newSearchParams = createQueryParams(tranformedQueryParams);
        setCurrentPresets(`?${newSearchParams}`);

        onPresetFilterChange({ value: newFilter.id, name: newFilter.name });
        customNavigate(`${pathname}?${newSearchParams.toString()}`);
      }
    }
  };

  useEffect(() => {
    if (search !== currentPresets && onPresetFilterChange) {
      setCurrentPresets(null);
      onPresetFilterChange(null);
    }
  }, [search]);

  return (
    <StyledContainer
      withTabs={withTabs}
      mx={2.5}
      mb={5}
      py={5}
      display="flex"
      flexDirection="column"
      gap={3}
      {...props}
    >
      <Box
        px={2.2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer>
          <Search
            initialValue={searchValue}
            onSubmit={onSubmitSearch}
            placeholder={searchPlaceholder}
          />
        </SearchContainer>
        <Box display="flex" gap={1} alignItems="center">
          {!!filterPresetOptions?.length && onPresetFilterChange && (
            <MyFiltersButton
              placeholder="Meine Filter"
              value={presetFilterValue || null}
              action={(event: SelectChangeEvent<any>) =>
                handlePresetFilterChange(event)
              }
              options={filterPresetOptions.map(({ id, name }) => ({
                name,
                value: id,
              }))}
            />
          )}
          {activeFiltersAmount !== null && (
            <FiltersButton
              activeFiltersAmount={activeFiltersAmount}
              onClick={onToggleFilters}
            />
          )}
        </Box>
      </Box>
      {children}
    </StyledContainer>
  );
};
