import { StartView } from 'components/User/StartView';
import { FC } from 'react';
import { ReactComponent as ConfirmIcon } from 'assets/Icons/done.svg';
import { Button } from 'components/common/Buttons/Button';
import { AppPaths } from 'urls/frontend';
import {
  StyledContainer,
  StyledTypography,
} from './ResetPasswordConfirmed.styled';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const ResetPasswordConfirmed: FC = () => {
  const { customNavigate } = useNavigation();

  return (
    <StartView headline="Ihr Passwort wurde geändert" icon={<ConfirmIcon />}>
      <StyledContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <StyledTypography variant="body">
          Sie können sich jetzt mit Ihrem neuen Passwort anmelden
        </StyledTypography>
        <Button onClick={() => customNavigate(AppPaths.login)}>
          Zurück zum Login
        </Button>
      </StyledContainer>
    </StartView>
  );
};
