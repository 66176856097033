import { FC } from 'react';
import { SFDRClassificationChartProps } from './SFDRClassificationChart.d';
import {
  StyledContainer,
  StyledDoughnutContainer,
  StyledHead,
  StyledLabel,
  StyledPoint,
} from './SFDRClassificationChart.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { backgroundColors } from './config';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { convertToPercentage } from 'utils/common';

export const SFDRClassificationChart: FC<SFDRClassificationChartProps> = ({
  data,
}) => {
  const options: ChartOptions<'doughnut'> = {
    cutout: '70%',
    radius: 96,
    aspectRatio: 1,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const chartData: ChartData<'doughnut', number[]> = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value || 0),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };

  return (
    <Box>
      <StyledHead>Anteilige AuM gemäß SFDR Klassifizierung</StyledHead>
      <StyledContainer px={3} pt={3.5} pb={3.65}>
        {data.length !== 0 ? (
          <>
            <StyledDoughnutContainer
              pt={9}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Doughnut options={options} data={chartData} />
            </StyledDoughnutContainer>
            <Box pt={9.5} pb={3} display="flex" justifyContent="space-around">
              {data.map((item, index) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    {backgroundColors[index] && (
                      <StyledPoint color={backgroundColors[index]} />
                    )}
                    <StyledLabel variant="body" color={COLORS.accent.secondary}>
                      {item.label}
                    </StyledLabel>
                  </Box>
                  <Typography
                    variant="subtitle"
                    weight="bold"
                    color={COLORS.accent.secondary}
                  >
                    {item.value !== null && item.value !== undefined
                      ? convertToPercentage(item.value)
                      : '-'}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <StyledDoughnutContainer
            pt={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body">Keine Daten gefunden</Typography>
          </StyledDoughnutContainer>
        )}
      </StyledContainer>
    </Box>
  );
};
