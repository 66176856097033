import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

type StyledTabButtonProps = {
  active: boolean;
};

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(7.5)} ${SIZES.spacing(5)}
    ${SIZES.spacing(5)};
  background-color: ${COLORS.background.module};
  min-height: 78vh;
`;

export const StyledHeadline = styled(Typography)`
  font-size: 40px;
  line-height: 48px;
  color: ${COLORS.typography.primary};
`;

export const StyledTabsContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 16px;

  min-height: 65vh;
  background-color: ${COLORS.background.secondary};
  margin-top: ${SIZES.spacing(5)};

  display: grid;
  grid-template-columns: 273px 1fr;
`;

export const StyledLeftContainer = styled(Box)`
  border-right: 1px solid ${COLORS.stroke.primary};
  padding: ${SIZES.spacing(3)};

  display: flex;
  flex-direction: column;
  gap: ${SIZES.spacing(1)};
`;

export const tabButtonStyles = css<StyledTabButtonProps>`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: ${({ active }) =>
    active ? COLORS.info.selectedBorder : 'unset'};
  color: ${({ active }) =>
    active ? COLORS.background.secondary : COLORS.typography.description};
  cursor: pointer;
  transition-duration: 0.2s;
  height: 48px;
  padding: ${SIZES.spacing(1.5)} ${SIZES.spacing(2.5)};

  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  display: flex;
  align-items: center;
  gap: ${SIZES.spacing(1)};

  &:hover {
    color: ${({ active }) =>
      active ? COLORS.background.neutral : COLORS.info.selectedBorder};
  }
`;

export const StyledAccountInfoTabButton = styled.button<StyledTabButtonProps>`
  ${tabButtonStyles}

  & svg {
    ${({ active }) => active && `fill: ${COLORS.background.secondary};`}
    ${({ active }) => !active && `stroke: ${COLORS.typography.description};`}
  }

  &:hover {
    svg {
      ${({ active }) => active && `fill: ${COLORS.background.neutral};`}
      ${({ active }) => !active && `stroke: ${COLORS.info.selectedBorder};`}
    }
  }
`;

export const StyledChangePasswordTabButton = styled.button<StyledTabButtonProps>`
  ${tabButtonStyles}

  & svg {
    ${({ active }) => active && `fill: ${COLORS.background.secondary};`}
    ${({ active }) => !active && `stroke: ${COLORS.typography.description};`}
  }

  &:hover {
    svg {
      ${({ active }) => active && `fill: ${COLORS.background.neutral};`}
      ${({ active }) => !active && `stroke: ${COLORS.info.selectedBorder};`}
    }
  }
`;
