import styled from 'styled-components';
import { FieldBasic } from '../Form/common';
import { StyledFieldBasic } from '../Form/common.styled';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';
import { StyledInputProps } from './Input.d';

export const StyledInput = styled.input<FieldBasic>`
  ${StyledFieldBasic}
`;

export const StyledContainer = styled(Box)`
  position: relative;
`;

export const InputIcon = styled.span<StyledInputProps>`
  cursor: pointer;
  position: absolute;
  right: 16.5px;
  top: ${({ withLabel }) => (withLabel ? '41px' : '16.5px')};
`;

export const StyledEyeIcon = styled.span<StyledInputProps>`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: ${({ withLabel }) => (withLabel ? '37px' : '13px')};
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${COLORS.accent.primary};
    }
  }
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;
