import styled from 'styled-components';
import { Box } from '../../common/Box';
import { SIZES } from 'theme/sizes';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonContainer = styled(Box)`
  height: 50px;
  margin-top: ${SIZES.spacing(2)};

  ${SIZES.media.phone} {
    margin-top: ${SIZES.spacing(1)};
  }
`;
