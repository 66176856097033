import { Box } from 'components/common/Box';
import { ArrowIconButton } from 'components/common/IconButtons/ArrowIconButton';
import { Layout } from 'components/common/Layout';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import {
  StyledAccountInfoTabButton,
  StyledChangePasswordTabButton,
  StyledContainer,
  StyledHeadline,
  StyledLeftContainer,
  StyledTabsContainer,
} from './Profile.styled';
import { ReactComponent as ProfileIcon } from 'assets/Icons/profile-circle.svg';
import { ReactComponent as ProfileFilledIcon } from 'assets/Icons/profile-circle-filled.svg';
import { ReactComponent as ChangePasswordFilledIcon } from 'assets/Icons/pinned-icon-filled.svg';
import { ReactComponent as ChangePasswordOutlinedIcon } from 'assets/Icons/pinned-icon-outlined.svg';
import { ProfileForm } from 'components/User/ProfileForm';
import { ChangePasswordForm } from 'components/User/ChangePasswordForm';

export const Profile: FC = () => {
  const [prevPath, setPrevPath] = useState(AppPaths.main);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state, pathname } = useLocation();
  const tab = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(tab || 'account-info');
  const tabs = {
    accountInfo: {
      icon: <ProfileIcon />,
      activeIcon: <ProfileFilledIcon />,
      id: 'account-info',
      label: 'Kontoinformationen',
    },
    changePassword: {
      icon: <ChangePasswordOutlinedIcon />,
      activeIcon: <ChangePasswordFilledIcon />,
      id: 'password',
      label: 'Kennwort ändern',
    },
  };

  useEffect(() => {
    state?.prevPath && setPrevPath(state.prevPath);
  }, [state]);

  const onTabChange = (tabId: string) => {
    setActiveTab(tabId);
    const params = new URLSearchParams();
    params.delete('tab');
    if (tabId) {
      params.append('tab', tabId);
    }
    navigate(pathname + '?' + params.toString());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <StyledContainer>
        <Box
          display="flex"
          gap={4}
          alignItems="center"
          justifyContent="flex-start"
        >
          <ArrowIconButton variant="back" to={prevPath} />
          <StyledHeadline variant="h1">Kontoeinstellungen</StyledHeadline>
        </Box>
        <StyledTabsContainer>
          <StyledLeftContainer>
            <StyledAccountInfoTabButton
              key={tabs.accountInfo.id}
              active={activeTab === tabs.accountInfo.id}
              onClick={() => onTabChange(tabs.accountInfo.id)}
            >
              {activeTab === tabs.accountInfo.id
                ? tabs.accountInfo.activeIcon
                : tabs.accountInfo.icon}
              {tabs.accountInfo.label}
            </StyledAccountInfoTabButton>
            <StyledChangePasswordTabButton
              key={tabs.changePassword.id}
              active={activeTab === tabs.changePassword.id}
              onClick={() => onTabChange(tabs.changePassword.id)}
            >
              {activeTab === tabs.changePassword.id
                ? tabs.changePassword.activeIcon
                : tabs.changePassword.icon}
              {tabs.changePassword.label}
            </StyledChangePasswordTabButton>
          </StyledLeftContainer>
          <Box p={3}>
            {activeTab === tabs.accountInfo.id ? (
              <ProfileForm />
            ) : (
              <ChangePasswordForm />
            )}
          </Box>
        </StyledTabsContainer>
      </StyledContainer>
    </Layout>
  );
};
