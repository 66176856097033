import { FC, useEffect, useState } from 'react';
import { SelectedFiltersProps } from './SelectedFilters.d';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { useLocation } from 'react-router-dom';
import { Box } from 'components/common/Box';
import { FilterChip } from 'components/common/Chip/FilterChip';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { Button } from 'components/common/Buttons/Button';

export const SelectedFilters: FC<SelectedFiltersProps> = ({
  filters,
  clearFilters,
  openSaveFiltersPresetModal,
  presetFilterValue,
  onPresetFilterChange,
}) => {
  const { customNavigate } = useNavigation();
  const { pathname, search } = useLocation();
  const selectedFilters = filters.filter((item) => !!item.value);
  const [currentFilter, setCurrentFilter] = useState({ id: '', value: '' });

  const onDeleteFilter = (id: string, value: string) => {
    const params = new URLSearchParams(search);
    if (params.has(id)) {
      for (const [paramKey, paramValue] of params) {
        if (
          paramKey === id &&
          paramValue.toLowerCase() === value.toLowerCase()
        ) {
          const newParams = params
            .getAll(paramKey)
            .filter((item) => item !== value.toLowerCase());
          params.delete(id);
          newParams.forEach((item) => params.append(id, item.toLowerCase()));
        }
      }
    }
    params.delete('page');
    customNavigate(pathname + '?' + params.toString());
    setCurrentFilter({ id: '', value: '' });
  };

  useEffect(() => {
    !!currentFilter.id &&
      !!currentFilter.value &&
      onDeleteFilter(currentFilter.id, currentFilter.value);
  }, [currentFilter]);

  return (
    <>
      {selectedFilters.length !== 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          gap={2}
          px={2.2}
        >
          <Box display="flex" gap={1} flexWrap="wrap">
            {selectedFilters.map((filter) => (
              <FilterChip
                label={filter.label}
                value={filter.name}
                handleDelete={() => {
                  onDeleteFilter(filter.id, filter.value);
                  setCurrentFilter({ id: filter.id, value: filter.value });
                  onPresetFilterChange(null);
                }}
              />
            ))}
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Button variant={ButtonVariant.OUTLINED} onClick={clearFilters}>
              Alle Filter löschen
            </Button>
            {presetFilterValue === null && (
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={openSaveFiltersPresetModal}
              >
                Als Vorlage speichern
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
