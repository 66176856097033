import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  margin: 16px 20px;
  width: 90%;
  gap: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    width: 73%;
    margin: 25px auto;
  }

  ${SIZES.media.tablet} {
    width: 43%;
  }

  ${SIZES.media.desktop} {
    gap: ${SIZES.spacing(3)};
    width: 33%;
  }
`;

export const StyledDescription = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  width: 95%;
  margin: 0 auto;
  text-align: center;
  color: ${COLORS.typography.description};

  ${SIZES.media.phone} {
    width: 100%;
  }
`;

export const StyledButtonsContainer = styled(Box)`
  margin-top: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    margin-top: ${SIZES.spacing(3)};
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.typography.description};
`;
