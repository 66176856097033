import { forwardRef } from 'react';
import { AddFeedbackModalProps, FeedbackForm } from './AddFeedbackModal.d';
import { StyledContainer } from './AddFeedbackModal.styled';
import { Modal } from 'components/common/Modal/Modal';
import { Button } from 'components/common/Buttons/Button';
import { Box } from 'components/common/Box';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Textarea } from 'components/common/Textarea';
import { Radio } from 'components/common/Radio';
import { Typography } from 'components/common/Typography';
import { interestLevels } from './config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { feedbackFormValidationSchema } from './validators';
import {
  fetchSendFundFeedbackPrivateMarkets,
  fetchSendFundFeedbackSmartBenchmarking,
} from 'services/Modules';
import { fetchSendFundFeedbackESG } from 'services/ESG';
import { raiseToast } from 'components/common/Toast/raiseToast';

export const AddFeedbackModal = forwardRef<
  HTMLDivElement,
  AddFeedbackModalProps
>(
  (
    { isShown, withInterestLevel = false, toggle, currentFund, variant },
    ref,
  ) => {
    const { name } = currentFund;

    const { handleSubmit, register, watch, reset } = useForm<FeedbackForm>({
      resolver: yupResolver(feedbackFormValidationSchema),
      mode: 'onSubmit',
    });

    const interestLevel = watch('interestLevel');

    const getResponse = async (feedbackFormData: FeedbackForm) => {
      switch (variant) {
        case 'private-markets':
          return fetchSendFundFeedbackPrivateMarkets({
            id: currentFund.id,
            text: feedbackFormData.feedback,
            relevance_level: feedbackFormData.interestLevel || '',
          });
        case 'smart-benchmarking':
          return fetchSendFundFeedbackSmartBenchmarking({
            id: currentFund.id,
            text: feedbackFormData.feedback,
            relevance_level: feedbackFormData.interestLevel || '',
          });
        default:
          return fetchSendFundFeedbackESG({
            id: currentFund.id,
            text: feedbackFormData.feedback,
          });
      }
    };

    const onSubmit = async (feedbackFormData: FeedbackForm) => {
      const response = await getResponse(feedbackFormData);
      if (response.ok) {
        raiseToast.success(
          `Die Rückmeldung wurde erfolgreich gesendet: ${currentFund.name}.`,
        );
        toggle();
        reset();
      }
    };

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        withHelpBox
        title={name}
        p={4}
      >
        <StyledContainer pt={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {withInterestLevel && (
              <>
                <Typography variant="h5">Ich finde den Fonds ...</Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1.5}
                  mt={3}
                  mb={4}
                >
                  {interestLevels.map((level) => (
                    <Radio
                      key={level.label}
                      {...register('interestLevel')}
                      value={level.value}
                      label={level.label}
                      selectedValue={interestLevel || ''}
                    />
                  ))}
                </Box>
              </>
            )}
            <Box>
              <Textarea
                placeholder="Ihr Feedback..."
                {...register('feedback')}
              />
              <Box display="flex" justifyContent="flex-end" gap={2} pt={4}>
                <Button
                  variant={ButtonVariant.OUTLINED}
                  onClick={toggle}
                  type="submit"
                >
                  Zurück
                </Button>
                <Button variant={ButtonVariant.PRIMARY}>Abschicken</Button>
              </Box>
            </Box>
          </form>
        </StyledContainer>
      </Modal>
    );
  },
);
