import styled from 'styled-components';
import { StyledStartViewProps } from './StartView.d';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { SIZES } from 'theme/sizes';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';

export const StyledHeader = styled.header`
  margin: 0 ${SIZES.spacing(5)};

  display: flex;
  justify-content: center;

  ${SIZES.media.phone} {
    justify-content: flex-start;
  }
`;

export const StyledHeadlineContainer = styled(Box)<StyledStartViewProps>`
  padding-top: ${({ withIcon }) =>
    withIcon ? SIZES.spacing(2) : SIZES.spacing(15)};
  width: 90%;
  margin: 0 auto;

  ${SIZES.media.tablet} {
    padding-top: ${({ withIcon }) =>
      withIcon ? SIZES.spacing(2) : SIZES.spacing(12.5)};
    width: 100%;
  }
`;

export const StyledHeadline = styled(Typography)`
  font-size: 28px;
  line-height: 40px;

  ${SIZES.media.tablet} {
    font-size: 42px;
    line-height: 60px;
  }
`;

export const StyledLogo = styled(Logo)`
  margin: 25px auto 0;
  width: 180px;
`;

export const StyledContent = styled(Box)`
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${SIZES.media.phone} {
    height: 80vh;
  }
`;
