import { forwardRef } from 'react';
import {
  StyledContainer,
  StyledInput,
  InputIcon,
  StyledErrorText,
  StyledSelect,
  StyledMenuItem,
  StyledSelectInput,
} from './PhoneInput.styled';
import { PhoneInputProps } from './PhoneInput.d';
import { ReactComponent as DangerIcon } from 'assets/Icons/danger.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow.svg';
import { COLORS } from 'theme/colors';
import { Box } from '../Box';
import { Label } from '../Label';

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      label,
      codeValue,
      setCodeValue,
      defaultCodeValue,
      options,
      error,
      codeError,
      disabled,
      id,
      name = id,
      required,
      type,
      phoneCodeName,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledContainer display="flex" flexDirection="column" gap={0.5}>
        {label && (
          <Label
            required={required}
            htmlFor={id}
            disabled={disabled}
            error={!!error}
          >
            {label}
          </Label>
        )}
        <Box display="flex">
          <StyledSelect
            name={phoneCodeName}
            value={codeValue?.country}
            onChange={(event) => {
              setCodeValue(event.target.value as string);
            }}
            input={
              <StyledSelectInput value={codeValue ? codeValue.value : ''} />
            }
            MenuProps={{
              MenuListProps: {
                sx: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            }}
            IconComponent={(props) => (
              <ArrowIcon {...props} stroke={COLORS.typography.primary} />
            )}
            renderValue={(valueP: any) => <span>{codeValue?.value || ''}</span>}
          >
            {options.map((option) => (
              <StyledMenuItem key={option.value} value={option.country}>
                {option.name}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          <StyledInput
            id={id}
            name={name}
            disabled={disabled}
            error={!!error}
            ref={ref}
            type="tel"
            {...props}
          />
        </Box>
        {error && <StyledErrorText>{error}</StyledErrorText>}
        {codeError && <StyledErrorText>{codeError}</StyledErrorText>}
        {(error || codeError) && (
          <InputIcon withLabel={!!label}>
            <DangerIcon />
          </InputIcon>
        )}
      </StyledContainer>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
