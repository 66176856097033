import { FC } from 'react';
import { SpecialFeaturesETFProps } from './SpecialFeaturesETF.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledInfoContainer,
  StyledInfoItem,
  StyledTypography,
} from './SpecialFeaturesETF.styled';
import { COLORS } from 'theme/colors';

const YES = 'Ja';
const NO = 'Nein';

export const SpecialFeaturesETF: FC<SpecialFeaturesETFProps> = ({
  description,
  benchmarkName,
  activeManagement,
  indexWeighting,
  physicalReplication,
  securitiesLending,
}) => {
  const getBooleanData = (value: boolean | null) => {
    if (value === null) {
      return 'Keine Daten gefunden';
    } else {
      return value ? YES : NO;
    }
  };

  const data = [
    { label: 'Benchmark', value: benchmarkName },
    { label: 'Aktives Management', value: getBooleanData(activeManagement) },
    { label: 'Indexgewichtung', value: indexWeighting },
    {
      label: 'Physische Replikation',
      value: getBooleanData(physicalReplication),
    },
    { label: 'Wertpapierleihe', value: getBooleanData(securitiesLending) },
  ];

  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <StyledInfoContainer py={3} my={2} display="grid">
          {data.map((item) => (
            <StyledInfoItem
              key={item.label}
              display="flex"
              justifyContent="space-between"
            >
              <StyledTypography variant="body" weight="semibold">
                {item.label}
              </StyledTypography>
              <StyledTypography variant="body">
                {item.value || '-'}
              </StyledTypography>
            </StyledInfoItem>
          ))}
        </StyledInfoContainer>
      )}
    </Box>
  );
};
