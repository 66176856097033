import { useState, FC, useEffect } from 'react';
import { AccordionType } from './Accordion.d';
import { slugify } from 'utils/common';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledCount,
  StyledLabel,
} from './Accordion.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-up.svg';
import { COLORS } from 'theme/colors';
import { Box } from '../Box';

export const Accordion: FC<AccordionType> = ({ tabs, onTabChange }) => {
  const { search } = useLocation();
  const [activeTab, setActiveTab] = useState<string | false>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const peerGroup = searchParams.get('peer_group_id') || '';

  useEffect(() => {
    if (peerGroup.length !== 0) {
      setActiveTab(slugify(peerGroup));
    }
  }, [peerGroup]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      setActiveTab(newExpanded ? slugify(panel) : false);
      onTabChange && onTabChange(panel);
      const newSearchParams = new URLSearchParams(search);

      if (newExpanded) {
        newSearchParams.set('peer_group_id', panel);
      } else {
        newSearchParams.delete('peer_group_id');
      }
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    };

  return (
    <>
      {tabs.length !== 0 &&
        tabs.map((tab) => {
          const isActive = slugify(tab.id) === activeTab;

          return (
            <StyledAccordion
              key={tab.id}
              expanded={isActive}
              onChange={handleChange(tab.id)}
              disableGutters
              elevation={0}
              square
              slotProps={{ transition: { unmountOnExit: true } }}
            >
              <StyledAccordionSummary
                id={tab.id}
                disabled={tab.count === 0}
                expandIcon={
                  <ArrowIcon
                    fill={
                      isActive ? COLORS.accent.primary : COLORS.typography.main
                    }
                    width={24}
                    height={24}
                  />
                }
              >
                <Box display="flex" gap={1}>
                  <StyledLabel variant="h4">{tab.label}</StyledLabel>
                  {tab.count !== null && tab.count !== undefined ? (
                    <StyledCount className="count" active={isActive}>
                      {tab.count}
                    </StyledCount>
                  ) : null}
                </Box>
              </StyledAccordionSummary>
              <StyledAccordionDetails>{tab.content}</StyledAccordionDetails>
            </StyledAccordion>
          );
        })}
    </>
  );
};
